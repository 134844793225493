import React, { useEffect } from 'react'
import MeditationModule from './MeditationModule'
import { useState } from 'react'
const Complete = () => {
    const [showHome, setShowHome] = useState(false)
    useEffect(()=>{
        if(showHome){
            document.location.reload()
        }
    },[showHome])
  return (
    <div>
        <div className="fade-in-text">
            <h3>
                Good Job, we will see you tomorrow. Enjoy your calm mind.
            </h3>
            <button onClick={()=>{setShowHome(true)}}>
                Back To Home
            </button>
        </div>
    </div>
  )
}

export default Complete