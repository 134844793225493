import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <div className="headerContainer">
      <h3 className="headerText">Teflon Brain</h3>
    </div>
  );
};

export default Header;
