import React, { useState, useEffect } from 'react';
import './MeditationModule.css';
import About from './About';
import KobeExample from './KobeExample';
import Slider from './Slider';
import MeditationTimer from './MeditationTimer';
import Header from './Header';
import NavalExample from './NavalExample';
import AdditionalInfo from './AdditionalInfo';
import StartModal from './StartModal';

const MeditationModule = () => {
  const [showTimer, setShowTimer] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [value, setValue] = useState(0); // 0 = Beginner, 1 = Intermediate, 2 = Expert
  const [seconds, setSeconds] = useState(600);

  const handleChange = (e) => {
    setValue(Number(e.target.value));
  };

  useEffect(() => {
    // Update the timer duration based on the selected value
    switch (value) {
      case 0:
        setSeconds(600); // 10 minutes
        break;
      case 1:
        setSeconds(1200); // 20 minutes
        break;
      case 2:
        setSeconds(2700); // 45 minutes
        break;
      default:
        setSeconds(600);
    }
  }, [value]);

  const getMode = () => {
    switch (value) {
      case 0:
        return '10 Minutes';
      case 1:
        return '20 Minutes';
      case 2:
        return '45 Minutes';
      default:
        return '10 Minutes';
    }
  };

  const handleStart = () => {
    setShowModal(false);
    setShowTimer(true);
  };

  return (
    <div className="moduleContainer">
      <Header />

      {/* Modal for Instructions */}
      <StartModal show={showModal} handleClose={handleStart} />

      {/* Meditation Module Content */}
      {!showTimer && !showModal && (
        <div className="homeContainer">
            
          <div className="sliderContainer">
            <h3>Duration: {getMode()}</h3>
            <div className="sliderWrapper">
              <input
                type="range"
                min="0"
                max="2"
                step="1"
                value={value}
                onChange={handleChange}
                className="slider"
              />
              <div className="sliderLabels">
                <span>10 Minutes</span>
                <span>20 Minutes</span>
                <span>45 Minutes</span>
              </div>
            </div>
          </div>
          <div>
          <button
            className="beginTrainingButton"
            onClick={() => setShowModal(true)}
          >
            Begin Training
          </button>
          </div>
          <br />

          {/* Informational Sections */}
          <About />
          <br />
          <KobeExample />
          <br />
          <NavalExample />
          <br />
          <AdditionalInfo />
        </div>
      )}

      {/* Meditation Timer */}
      {showTimer && <MeditationTimer duration={seconds} />}
    </div>
  );
};

export default MeditationModule;
