import './App.css';
import Header from './components/Header';
import Home from './pages/Home';
import { motion } from 'framer-motion';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <div className="app-container">
      <motion.div 
        className="dynamic-gradient"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
          background: 'linear-gradient(your-gradient-colors)',
          backgroundSize: '200% 200%'
        }}
        initial={{ backgroundPosition: '0% 50%' }}
        animate={{ backgroundPosition: ['0% 50%', '100% 50%', '0% 50%'] }}
        transition={{
          duration: 5,
          ease: 'easeInOut',
          repeat: Infinity,
        }}
      />
      <Home/>
    </div>
  );
}

export default App;