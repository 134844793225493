import React from 'react';
import './NavalExample.css';

const NavalExample = () => {
  const navalPath = '/navalClip.png';
  return (
    <div className="navalExampleContainer">
      
      <h3 className="navalTitle">
        Billionaire Angel Investor Naval Ravikant on the Benefits of Simple Meditation
      </h3>
      <img src={navalPath} alt="Naval Meditation Image" className="navalImage" />
    </div>
  );
};

export default NavalExample;
