import React, { useState, useEffect } from "react";
import "./LoadingAnimation.css";
import Training from "./Training";
import Gratitude from "./Gratitude";
import MeditationModule from "./MeditationModule";
import Header from "./Header";
import About from "./About";
const LoadingAnimation = () => {



  return (
    <div className="loading-container">


        <div className="fade-in-text">

            <div>
            <MeditationModule/>
            </div>
            <div>
                <div>
                    
                </div>
            </div>
            </div>

    </div>
  );
};

export default LoadingAnimation;
