import React, { useState, useEffect } from 'react';
import Complete from './Complete';

const MeditationTimer = ({ duration }) => {
  const [timeLeft, setTimeLeft] = useState(duration);
  const [isRunning, setIsRunning] = useState(true);

  useEffect(() => {
    if (!isRunning || timeLeft <= 0) return;

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [isRunning, timeLeft]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  return (
    <div>
        {timeLeft > 0 ? (    <div style={styles.container}>
      <h1 style={styles.title}>Meditation Timer</h1>
      <div style={styles.timer}>{formatTime(timeLeft)}</div>
      <div style={styles.buttonContainer}>
        <button
          style={{ ...styles.button, ...(isRunning || timeLeft <= 0 ? styles.disabledButton : {}) }}
          onClick={() => setIsRunning(true)}
          disabled={isRunning || timeLeft <= 0}
        >
          Start
        </button>
        <button
          style={{ ...styles.button, ...(!isRunning ? styles.disabledButton : {}) }}
          onClick={() => setIsRunning(false)}
          disabled={!isRunning}
        >
          Pause
        </button>

      </div>
    </div>

        ) : (
            <Complete/>
        )}

    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '55vh',
    color: '#fff',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center',
  },
  title: {
    fontSize: '2.5rem',
    marginBottom: '1rem',
  },
  timer: {
    fontSize: '3rem',
    fontWeight: 'bold',
    marginBottom: '2rem',
  },
  buttonContainer: {
    display: 'flex',
    gap: '1rem',
  },
  button: {
    padding: '0.8rem 1.5rem',
    fontSize: '1rem',
    fontWeight: 'bold',
    color: '#000',
    backgroundColor: '#fff',
    border: '2px solid #fff',
    borderRadius: '0.5rem',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
  },
  disabledButton: {
    backgroundColor: '#555',
    color: '#999',
    cursor: 'not-allowed',
    border: '2px solid #555',
  },
};

export default MeditationTimer;
