import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './StartModal.css';

const StartModal = ({ show, handleClose }) => {
  return (
    <div
      className={`modal fade ${show ? 'show' : ''}`}
      style={{
        display: show ? 'block' : 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
      }}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="startModalLabel"
      aria-hidden={!show}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content custom-modal">
          <div className="modal-body">
            <h3 className="modal-title">Welcome to Teflon Brain Meditation</h3>
            <p className="modal-instructions">
              <strong>Instructions:</strong>
            </p>
            <ol className="modal-list">
              <li>Find a quiet, comfortable place free from distractions.</li>
              <li>Calm your mind by letting go of any racing thoughts.</li>
              <li>Focus your attention on the middle of your forehead.</li>
              <li>Embrace stillness and let peace settle over your mind.</li>
            </ol>
            <p className="modal-footer-text">
              When you're ready, begin your journey to mental clarity.
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary custom-button"
              onClick={handleClose}
            >
              Begin Meditation
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartModal;
