import React from 'react';
import './Example.css';

const Example = () => {
  return (
    <div className="exampleContainer">
      <h3 className="exampleTitle">Kobe Bryant on Meditation and Mental Stillness</h3>
      <div className="videoWrapper">
        <iframe
          className="videoFrame"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/PXsjNqI1OWA?si=wpokr91uauvi79mc"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Example;
