import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="aboutContainer">
      <h3 className="aboutTitle">Redefine Meditation</h3>
      <p className="aboutText">
        Teflon Brain offers a no-nonsense, systematic approach to mastering your mind. Think of it as strength training for your brain—a mental workout to combat overstimulation, distractions, and lack of focus. 
      </p>
      <p className="aboutText">
        This program is designed to build resilience and mental clarity. By training your brain to embrace stillness, you'll cultivate focus, discipline, and inner strength. Teflon Brain isn’t just meditation; it’s mental fortification.
      </p>
    </div>
  );
};

export default About;
