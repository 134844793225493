import React from 'react';
import './AdditionalInfo.css';

const AdditionalInfo = () => {
  return (
    <div className="additionalInfoContainer">
      <h3 className="additionalInfoTitle">Meditation as Exercise for the Mind</h3>
      <p className="additionalInfoText">
        The benefits of meditation and mindfulness are well documented. However, meditation often carries a connotation of being an airy, esoteric, and spiritual practice—finding your zen, connecting with the universe, and aligning your chakras. While this may attract some, it can be off-putting for the more practical-minded.
      </p>
      <p className="additionalInfoText">
        Teflon Brain reframes meditation as a mechanical, practical, and rugged discipline. We aren’t here to meditate and connect with the universe—we’re here to make our brain <strong>POWERFUL</strong>.
      </p>
      <p className="additionalInfoText">
        A powerful brain locks onto a task for an extended period without distractions or delays. A powerful mind focuses on what matters, ignores what cannot be controlled, and gets the job done.
      </p>
      <p className="additionalInfoText">
        Once you have control over your mind, you will truly find peace. 
      </p>
      <p className="additionalInfoHighlight">Peace is stillness.</p>
    </div>
  );
};

export default AdditionalInfo;
