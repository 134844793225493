import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './Home.css';
import LoadingAnimation from '../components/LoadingAnimation';
import Training from '../components/Training';
import Gratitude from '../components/Gratitude';
import Header from '../components/Header';
const Home = () => {
  return (
    <div>
      <LoadingAnimation/>


    </div>

             

      
  
  );
};

export default Home;
